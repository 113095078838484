<template>
  <div>
    <br>
    <br>
    <br>
   <span class="font-font-semibold">Click Untuk Update dan Transfer data!</span> 
   <br>
   <br>
   <button type="button"  class="btn btn-sm btn-dark" @click="duplikate()">
      <span class="typcn typcn-arrow-loop"></span>
    </button>
    <hr>
    <div v-if="ready">
      <div v-if="!this.vdata.nota" class="row">
        <div class="col-12">
          <div class="sm-form ">
              <label for="searchnota">Cari Nota : </label>
              <input type="text" id="searchnota" name="searchnota" class="form-control form-control-sm" placeholder="searchnota" v-model="searchnota" >
          </div>
        </div>
        <div class="col-md-6" v-for="(item, index) in td" :key="index+'listnota'">
          <div class="shadow p-3 rounded-lg mt-2 text-xs">  
            <span class="font-semibold float-right">
              {{item.nota}}
            </span>
            <p class="">Pelanggan : {{item.pelanggan}}</p>
            <p class="float-right">{{item.tanggal_w}}</p>
            <p class="">Total Harga : Rp. {{item.total}}</p>
            <hr class="mt-1 mb-1">
            <button type="button" @click="pinini(item)" :class="`${item.pin=='true'?'btn-success':'btn-outline-success'}`" class="btn btn-sm float-right"><span class="typcn typcn-pin"></span></button>
            <button type="button" @click="vdata.nota=item.nota;getDatanya();" class="btn btn-sm btn-dark text-xs">
              <span class="typcn typcn-news"></span>
              Ambil Nota
            </button>
          </div>
        </div>
      </div>
        <div 
        v-if="this.vdata.nota"
        class="mt-2 shadow p-3 rounded-lg" >
          <p class="font-semibold text-sm">Edit hapusTransaksi</p>
          <hr class="mt-1 mb-3">
          <!-- FORM TRANSAKSI KERANJANG -->
          <form  @submit.prevent="submit" >
            <div class="row justify-content-center">
              <div class=" col-sm-5 3"  style="font-size:12px;">
                <div class="shadow p-3 rounded-lg bg-white">
                <!-- INPUT -->
                <div class="sm-form">
                  <input
                    type="text"
                    id="id"
                    name="id"
                    class="form-control form-control-sm"
                    placeholder="id"
                    v-model="vdata.id"
                    disabled
                  />
                </div>
                <table class="table table-sm table-responsive">
                <!-- <tr>        
                  <td class="capitalize">tanggal</td>
                  <td class="pl-2 pr-2">:</td>
                  <td colspan="4">
                      <div class="sm-form ">
                          <input required type="datetime-local" id="tanggal" name="tanggal" class="form-control form-control-sm" placeholder="tanggal" v-model="vdata.tanggal" >
                      </div>
                  </td>
              </tr> -->
                <tr>        
                  <td class="capitalize">(F1) barcode</td>
                  <td class="pl-2 pr-2">:</td>
                  <td colspan="4">
                      <v-select id="f1" :options="produks" label="item_data" v-model="vdata.id_produk" @input="cekstok();hitung_total()" :reduce="e => e.id"></v-select>
                  </td>
              </tr>
                <tr>        
                  <td class="capitalize">Jumlah</td>
                  <td class="pl-2 pr-2">:</td>
                  <td colspan="4">
                      <div class="sm-form ">
                          <input required type="number" @change="hitung_total" :disabled='stok==0' min="1" :max="stok" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="vdata.jumlah" >
                      </div>
                      <div class="float-right">
                          <button type="button" class="btn btn-sm btn-dark  " id="tambah" @click="kurang">(F2) -</button>
                          <button type="button" class="btn btn-sm btn-dark  " id="kurang" @click="tambah">(F3) +</button>
                      </div>
                      <p class="text-xs text-red-300">Stok sisa : {{stok}}</p>
                  </td>
              </tr>
                <tr>        
                  <td class="capitalize">harga</td>
                  <td class="pl-2 pr-2">:</td>
                  <td>
                      <div class="sm-form "  v-if="$store.state.users">
                          <input type="number"  @change="hitung_total" id="harga" name="harga" class="form-control form-control-sm" placeholder="harga" v-model="vdata.harga" >
                      </div>
                  </td>
                    <td class="capitalize">diskon</td>
                  <td class="pl-2 pr-2">:</td>
                  <td>
                      <div class="sm-form " v-if="$store.state.users">
                          <input type="number"  @change="hitung_total" id="diskon" name="diskon" class="form-control form-control-sm" placeholder="diskon" v-model="vdata.diskon" >
                      </div>
                  </td>
              </tr>
                <tr>        
                  <td class="capitalize">total</td>
                  <td class="pl-2 pr-2">:</td>
                  <td class="font-bold text-lg" >
                      Rp. {{vdata.total}}
                  </td>
                    <td class="capitalize">Satuan</td>
                  <td class="pl-2 pr-2">:</td>
                  <td class="text-xs" >
                      <div v-if="$store.state.users">
                        <v-select :options="satuans"  label="satuan" v-model="vdata.satuan" @input="hitung_total()" :reduce="e => e.id"></v-select>
                      </div>
                  </td>
              </tr>
                <tr>        
                  <td class="capitalize">(F4) pelanggan</td>
                  <td class="pl-2 pr-2">:</td>
                  <td colspan="4">
                      <select id="selectpelanggan" class='form-control' v-model="vdata.id_pelanggan" @change="cekmembership">
                      <option v-for="(item, index) in pelanggans" :key="index+'pridils'" :value="item.id">{{item.nama}}</option>
                      </select>
                  </td>
              </tr>
                <tr v-if="vdata.id_pelanggan">        
                  <td class="capitalize">Membership</td>
                  <td class="pl-2 pr-2">:</td>
                  <td>
                    <span class="font-bold">
                      {{membership==0?'Bukan Membership':'Membership'}}
                    </span>
                    <span class="font-bold" v-if="membership==1">
                      <button type="button" class="btn btn-sm btn-primary  " @click="tambahDiskon">
                        {{diskon_membership}} %
                      </button>
                    </span>
                  </td>
                  <td class="capitalize">Total Beli Pelanggan</td>
                  <td class="pl-2 pr-2">:</td>
                  <td>
                    <span class="font-bold">
                      Rp. {{total_beli_pelanggan}}
                    </span>
                  </td>
              </tr>
              </table>
                <!-- END INPUT -->
                <!-- BUTTON PART -->
                <div class="mt-3 p-3">
                  <button
                    type="submit"
                    class="btn btn-sm"
                    id='btnsubmit'
                    :class="
                      btn == 'tambah'
                        ? 'btn-primary'
                        : btn == 'update'
                        ? 'btn-success'
                        : 'btn-danger'
                    "
                  >
                    <span class="typcn typcn-info"></span> (F5) Proses {{ btn }}
                  </button>
                  <div class="float-right">
                    <button
                      type="button"
                      v-show="btn!='tambah'"
                      @click="
                      gantiBtn();
                        
                      "
                      class="btn btn-sm btn-dark"
                    >
                      <span class="typcn typcn-edit"></span> Tambah
                    </button>
                    <button
                      type="button"
                      @click="btn = 'delete'"
                      v-show="btn!='tambah'"
                      class="ml-3 btn btn-sm btn-danger"
                    >
                      <span class="typcn typcn-delete"></span> Delete
                    </button>
                  </div>
                </div>
                </div>
                <!-- END BUTTON PART -->
              </div>
              <div class=" col-sm-7 p-2" style="font-size:12px;">
                <div class="shadow bg-white rounded-lg p-2" v-if="datanya.length>0">
                  <div  class="">
                    <div v-if="pilih=='adminapotek'||pilih=='Admin'||pilih=='SuperAdmin'">
                      <button  type="button" id="btnbayar" @click="modalbayar=true" class="btn btn-sm btn-style9   waves-effect float-right text-xs"><span class="typcn typcn-export"></span> Bayar</button>
                      <button  type="button" @click="deleteAll" class="btn btn-sm btn-dark  "><span class="typcn typcn-eject"></span> Delete All</button>
                    </div>
                  <br>
                  <br>
                  <p class="font-bold">Nota Transaksi : {{vdata.nota}}</p>
                      <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','stok','keluar','id_user','tanggal_w','id_pelanggan']" @selected="ambil" ref="btable" />
                  </div>
                </div>
              </div>
            
            </div>
          </form>
      </div>
      <!-- TABLE PART -->
      <!-- END TABLE PART -->
      <div style="height:200px;"></div>
    </div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/_autophp.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
let sdb2 = new autophp();
import firebase from 'firebase'
import uniqid from 'uniqid'
let rdb=firebase.database()
import _ from 'lodash'
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  computed:{
    td(){
      let that=this;
      let data=this.listnota;
      data=data.filter(e=>{
        if(e.tanggal_w.indexOf(that.searchnota)!=-1 || e.nota.toLowerCase().indexOf(that.searchnota.toLowerCase())!=-1 || e.pelanggan.toLowerCase().indexOf(that.searchnota.toLowerCase())!=-1){
          return e
        }
      })
      return data;
    }
  },
  data() {
    return {
      pilih:'adminapotek',
      ticketType:'latest',
      nota:null,
      searchnota:'',
      ready: true,
      selected: false,
      modalbayar:false,
      modaltransaksi:false,
      proses:'',
      vdata: {
          jumlah:0,
          catatan_admin:''
      },
      produks:[],
      transaksiNota:[],
      pelanggans:[],
      ticket:null,
      btn: "tambah",
      stok:0,
      datanya:[],
      total:0,
      uang:'',
      diskons:false,
      skip_ambil_diskon:false,
      satuans:[],
      total_beli_pelanggan:0,
      membership:0,
      diskon_membership:0
    };
  },
  watch:{
    pilih(){
      this.getProduknya()
    }
  },
  methods: {
      pinini(item){
        let data ={
          id:item.idnya,
          pin:'true'
        }
        sdb.collection('app_kasir_transaksi_nota2',false).doc().set(data).then(res=>{
          sdb.alert('Berhasil Update Pin!');
        })
      },
      duplikate(){
        let that=this;
          this.ready=false;
          sdb.collection("app_kasir_transaksi_nota2",false).doc().select(`INSERT INTO app_kasir_transaksi_nota2 SELECT * from app_kasir_transaksi_nota tb1 where tb1.id NOT IN (select id from app_kasir_transaksi_nota2)`).then(res => {
                sdb.collection("app_kasir_transaksi_nota2",false).doc().select(`INSERT INTO app_kasir_keranjang2 SELECT * from app_kasir_keranjang tb1 where tb1.id NOT IN (select id from app_kasir_keranjang2)`).then(res => {
                    sdb.alert('Berhasil Transfer Data!','bg-green-400')
                    that.ready=true
                    this.$forceUpdate();
                });
            });
      },
      ubahTicket(item){
        this.ticketType=item;
        this.$forceUpdate();
      },
      async hapusTransaksi(item){
        if(await sdb.confirm('Apakah yakin ingin menghapus data transaksi? ')){
            sdb.collection("app_kasir_transaksi_nota2",true).doc(item.id).delete().then(res => {
                this.getData();
                sdb.alert('Berhasil Delete data transaski!')
            }).catch(err=>{
              sdb.alert('Error menghapus data transaksi!')
            });
          }
      },
      gantiBtn(){
        this.btn = 'tambah';
        let nota=this.vdata.nota
        let proses=this.proses ;
        let id_pelanggan=this.vdata.id_pelanggan;
        let catatan_admin=this.vdata.catatan_admin;
        let catatan_dokter=this.vdata.catatan_dokter;
        let catatan_nurse=this.vdata.catatan_nurse;
        let catatan_apoteker=this.vdata.catatan_apoteker;
        this.vdata = {
          nota:nota,
          jumlah:0,
          harga:0,
          id_pelanggan:id_pelanggan
        };
        this.vdata.catatan_admin=catatan_admin;
        this.vdata.catatan_dokter=catatan_dokter;
        this.vdata.catatan_nurse=catatan_nurse;
        this.vdata.catatan_apoteker=catatan_apoteker;
        this.skip_ambil_diskon=false;
        // this.getTanggal();
        // this.getNota();
      },
      async cekmembership(){
        let that=this;
        this.membership=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.vdata.id_pelanggan}`)
        this.membership=parseInt(this.membership[0].membership)
        let listmembership = await sdb.collection("app_kasir_pelanggan_membership",false).doc().select(`select * from app_kasir_pelanggan_membership`)
        let diskon
        this.total_beli_pelanggan=await sdb.collection("app_kasir_transaksi_nota2",false).doc().select(`select * from app_kasir_transaksi_nota2 where id_pelanggan='${this.vdata.id_pelanggan}'`)
        this.total_beli_pelanggan=this.total_beli_pelanggan.reduce((e,n)=>{
          return e+(parseInt(n.total))
        },0)
        if(this.membership!=0){
          diskon=listmembership.filter(e=>{
            if(this.total_beli_pelanggan>parseInt(e.total_beli)){
              return e
            }
          })
          if(diskon.length>0){
            this.diskon_membership=parseInt(diskon[0].diskon)
          }
        }
        console.log(this.vdata.id_pelanggan,this.total_beli_pelanggan)
        this.$forceUpdate();        
        
      },
      tambahDiskon(){
        this.vdata.diskon=parseInt(this.vdata.diskon)+parseInt(this.diskon_membership)
        this.hitung_total();
        this.$forceUpdate();
      },
      async tambahPointPelanggan(){
        if(parseInt(this.getJam())<16){ // jam pagi
          let data={}
          data.id = this.vdata.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.vdata.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+25
          console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              console.log(res)
          })
        }else if(parseInt(this.getJam())>16){ // jam sore/malam
           let data={}
          data.id = this.vdata.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.vdata.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+35
          console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              console.log(res)
          })
        }
    
      },
      tambah(){
        if(this.vdata.jumlah>parseInt(this.stok)-1){
          
          }else{
            this.vdata.jumlah=parseInt(this.vdata.jumlah)+1;
            this.hitung_total()
          }
      },
      kurang(){
        if(this.vdata.jumlah<1){

        }else{
          this.vdata.jumlah=parseInt(this.vdata.jumlah)-1;
          this.hitung_total()
        }
      },
      format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
      format2(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD HH:mm");
      },
      format3(tanggal) {
        return this.$datefns.format(new Date(tanggal),"DD-MM-YYYY");
      },
      format4(tanggal) {
        return this.$datefns.format(new Date(tanggal),"DD-MMMM-YYYY");
      },
      getJam(tanggal) {
        return this.$datefns.format(new Date(),"HH");
      },
      getNota(){
          let code='KA';//KA = kasir, ON=Online, OF=OFFLINE
          sdb.collection("app_kasir_transaksi_nota2").doc().get("*",`where id_user='${this.$store.state.users.id}' order by id`).then(res => {
              this.vdata.nota=code+this.format(new Date()).split('-').join('')+'-'+this.$store.state.users.id+'-'+res.length;
              console.log('nota',this.vdata.nota)
              this.$forceUpdate();
          });
      },
     async cekstok(item){
        console.log(this.vdata)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.vdata.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.vdata.diskon=res[0].diskon
         }
          this.vdata.harga=res[0].harga
          this.vdata.satuan=res[0].satuan
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      // let transaksi=await sdb.collection("app_kasir_transaksi_nota2",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota2 tb1 left join app_kasir_keranjang2 tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.vdata.id_produk}' order by tb1.id`).then(res => {
      //      return res.reduce((e,n)=>{
      //       return e+parseInt(n.jumlah)
      //       },0)
      // });
       let keranjang=await sdb.collection("app_kasir_keranjang2",false).doc().select(`SELECT * FROM app_kasir_keranjang2  tb1 where tb1.id_produk='${this.vdata.id_produk}' order by tb1.id`).then(res => {
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-keranjang)
      this.stok=stok;
      this.hitung_total();
      this.$forceUpdate();
    },
    hitung_diskon(){
        let data= this.datanya;
        if(data.length>0){
            this.total=data.reduce((e,n)=>{
                let diskon = (parseInt(n.diskon)/100)*parseInt(n.harga)
                return e+((parseInt(n.harga)*parseInt(n.jumlah))-diskon)
            },0)
            this.diskons=true;
        }
        this.$forceUpdate();
    },
    hitung_total(){
      console.log('satuans',this.satuans)
        let satuan=parseInt(this.satuans.filter(e=>e.id==this.vdata.satuan)[0].perkalian)
        this.vdata.total=(parseInt(this.vdata.harga)*satuan)*this.vdata.jumlah-((parseInt(this.vdata.diskon)/100)*parseInt(this.vdata.harga)*this.vdata.jumlah)
        this.$forceUpdate();
    },
    deleteAll(){
        if(confirm('apakah yakin menghapus semua data?')){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang2",false).doc().select(`DELETE FROM app_kasir_keranjang2 WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
        }
    },
    deleteAll2(){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang2",false).doc().select(`DELETE FROM app_kasir_keranjang2 WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
    },
    getListNota(){
        sdb.collection("app_kasir_keranjang2",false).doc().select(`select *,tb1.id as idnya,tb2.nama as pelanggan from app_kasir_transaksi_nota2 tb1 left join app_kasir_pelanggan tb2 on tb2.id=tb1.id_pelanggan where tb1.status='3'`).then(res => {
            this.listnota=res;
            this.$forceUpdate();
        });
    },
    getDatanya(){
      sdb.collection("app_kasir_keranjang2",false).doc().select(`
      select tb1.id,tb1.id_user,tb1.nota,tb1.tanggal,tb1.id_pelanggan,tb3.nama,tb2.barcode,tb1.harga,tb1.jumlah,tb2.nama_produk,tb1.id_produk,tb1.diskon,tb1.total,tb1.tanggal_w
      from app_kasir_keranjang2 tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk left join app_kasir_pelanggan tb3 on tb3.id=tb1.id_pelanggan where tb1.nota='${this.vdata.nota}'`).then(res => {
        this.datanya=res;
        if(res.length>0){
            this.total=res.reduce((e,n)=>{
                return e+(parseInt(n.total))
            },0)
            let date = this.format(new Date()).split('-')[2]
            if(res[0].tanggal_w.split('-')[2]==date){
            }else{
                setTimeout(() => {
                    this.deleteAll2();
                }, 1000);
            }
            this.hitung_total();
        }
        this.$forceUpdate();
      });
    },
    getProduknya(){
      var type='1';
      // if(this.pilih=='dokter' || this.pilih=='nurse'){
      //   type='3'
      // }
      sdb.collection("app_kasir_produk").doc().get("*",` order by id`).then(res => {
          this.produks=res;
          this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
    },
    getData(){
      this.getDatanya();
      this.getProduknya();
       sdb.collection("app_kasir_pelanggan").doc().get("*","order by id").then(res => {
          this.pelanggans=res;
          this.pelanggans.map(function (x){ // taruh setelah produks di initial
                  return x.item_data = x.nama + ' / ' + x.nohp;
          });
          this.$forceUpdate();
      });
      sdb.collection("app_kasir_transaksi_nota2",false).doc().select(`
      select tb1.nota,tb1.id,tb1.tanggal,tb1.proses,tb2.id as id_pelanggan,tb2.nama as pelanggan,tb3.username as penginput,tb1.catatan_admin,tb1.catatan_dokter,tb1.catatan_apoteker,tb1.catatan_nurse from app_kasir_transaksi_nota2 tb1 left join app_kasir_pelanggan tb2 on tb2.id=tb1.id_pelanggan left join tbuser tb3 on tb3.id=tb1.id_user
      where tb1.status!='delete' order by id asc
      `).then(res => {
        this.transaksiNota=res;
        console.log('transaksinota',res)
        this.$forceUpdate();
      });
    
      
      
    },
    ambil(data) {
      let proses=this.proses ;
      let catatan_admin=this.vdata.catatan_admin;
      let catatan_dokter=this.vdata.catatan_dokter;
      let catatan_nurse=this.vdata.catatan_nurse;
      let catatan_apoteker=this.vdata.catatan_apoteker;
      this.vdata = data;
      this.vdata.catatan_admin=catatan_admin;
      this.vdata.catatan_dokter=catatan_dokter;
      this.vdata.catatan_nurse=catatan_nurse;
      this.vdata.catatan_apoteker=catatan_apoteker;
      this.cekstok();
      this.skip_ambil_diskon=true;
      console.log(data)
      this.btn = "update";
      this.$forceUpdate();
    },
    async bayar(){
      let that=this
      if(this.uang==0){
        alert('Uang tidak boleh 0')
      }else if(this.uang<this.total){
        alert('Uang tidak boleh kurang dari total harga')
      }
      else{
        if(confirm('Apakah yakin di proses ?')){
           let data = this.datanya;
            data=data.map(e=>{
                return e.id
            })
          let vdata={
            total:this.total,
            uang:this.uang,
            id_user:this.$store.state.users.id,
            id_pelanggan:this.datanya[0].id_pelanggan,
            tanggal_w:this.datanya[0].tanggal_w,
            cabang:this.$store.state.data.toko.cabang,
            keterangan:this.vdata.keterangan,
            nota:this.vdata.nota,
            tanggal:this.vdata.tanggal,
            status:'3',
            proses:'8',
            list_id:data.toString(),
          }
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_transaksi_nota2",false).doc().select(`select * from app_kasir_transaksi_nota2 where nota='${this.vdata.nota}'`).then(res => {
              vdata.id=res[0].id;
              sdb2.collection('app_kasir_transaksi_nota2').doc().set(vdata).then(res=>{
                sdb.collection("app_kasir_keranjang2",false).doc().select(`update app_kasir_keranjang2 set posisi='nota' WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res=>{
                    // that.tambahPointPelanggan()
                    that.$router.push(`/admin/report/transaksi_now?nota=${that.vdata.nota}`)
                })
              })
            });
            
        }
      }
    },
    async submit() {
      let that=this
      this.getTanggal()
      if(this.vdata.id_produk && parseInt(this.vdata.jumlah)>0 && this.vdata.id_pelanggan){
         let vdata={
           status:'keluar',
           tanggal_w:this.format(new Date(this.vdata.tanggal)),
           id_user:this.$store.state.users.id,
           posisi:'keranjang',
           beli:'kasir',
           cabang:this.$store.state.data.toko.cabang,
           diskon:this.vdata.diskon,
           harga:this.vdata.harga,
           id_pelanggan:this.vdata.id_pelanggan,
           id_produk:this.vdata.id_produk,
           jumlah:this.vdata.jumlah,
           tanggal:this.vdata.tanggal,
           nota:this.vdata.nota,
           total:this.vdata.total,
           satuan:this.vdata.satuan,
           catatan_admin:this.vdata.catatan_admin,
            catatan_dokter:this.vdata.catatan_dokter,
            catatan_nurse:this.vdata.catatan_nurse,
            catatan_apoteker:this.vdata.catatan_apoteker,
         }
          let cek = this.datanya.filter(e=>{
            if(e.id==that.vdata.id_produk){
              return e
            }
          })
          if (confirm("Apakah yakin proses dilanjutkan ?")) {
            if (this.btn == "tambah") {
              delete this.vdata.id;
             await sdb
                .collection("app_kasir_keranjang2")
                .doc()
                .set(vdata)
                  that.getData();
                  that.cekstok();
                  let nota=that.vdata.nota;
                  let id_pelanggan=that.vdata.id_pelanggan
                  that.vdata= {
                    jumlah:0,
                    nota:nota,
                    id_pelanggan:id_pelanggan,
                    catatan_admin:vdata.catatan_admin,
                    catatan_dokter:vdata.catatan_dokter,
                    catatan_nurse:vdata.catatan_nurse,
                    catatan_apoteker:vdata.catatan_apoteker,
                  }
                  that.getTanggal();
    
            } else if (this.btn == "update") {
              vdata.id=this.vdata.id
              await sdb
                .collection("app_kasir_keranjang2")
                .doc()
                .set(vdata)
                  that.getData();
                  that.cekstok();
                   let nota=that.vdata.nota;
                  let id_pelanggan=that.vdata.id_pelanggan
                  that.vdata= {
                    jumlah:0,
                    nota:nota,
                    id_pelanggan:id_pelanggan,
                    catatan_admin:vdata.catatan_admin,
                    catatan_dokter:vdata.catatan_dokter,
                    catatan_nurse:vdata.catatan_nurse,
                    catatan_apoteker:vdata.catatan_apoteker,
                  }
                  that.getTanggal();
            } else if (this.btn == "delete") {
              await sdb
                .collection("app_kasir_keranjang2")
                .doc(this.vdata.id)
                .delete()
                  that.getData();
                  that.cekstok();
                    let nota=that.vdata.nota;
                  let id_pelanggan=that.vdata.id_pelanggan
                  that.vdata= {
                    jumlah:0,
                    nota:nota,
                    id_pelanggan:id_pelanggan,
                    catatan_admin:vdata.catatan_admin,
                    catatan_dokter:vdata.catatan_dokter,
                    catatan_nurse:vdata.catatan_nurse,
                    catatan_apoteker:vdata.catatan_apoteker,
                  }
                  that.getTanggal();
            }
          }
      }else{
        sdb.alert('Isi Barcode, Jumlah, dan data pelanggan terlebih dahulu!')
      }
    },
    getTanggal(){
        this.vdata.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")
    },
    getSatuan(){
      sdb.collection("app_kasir_produk_satuan").doc().get("*","order by id").then(res => {
          this.satuans=res;
          this.$forceUpdate();
      });
      
    }
  },
  async mounted() {
    this.getListNota();
     this.vdata= {
          jumlah:0,
          catatan_admin:''
     }
    this.pilih=this.$store.state.users.level
    let that=this;
    //  rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
    //       if(that.produks.length>0){
    //         console.log('produks',that.produks)
    //         let data =that.produks.filter(k=>{
    //           console.log(k.barcode)
    //           if(k.barcode==e.val().id){
    //             return k
    //           }
    //         })
    //         console.log(data)
    //         that.vdata.id_produk=data[0].id
    //         that.cekstok();
    //         that.$forceUpdate();
    //       }
    // }) 
    // rdb.ref(`/ticket/admin`).on('value',(e)=>{ //read
    //     that.getData();
    // }) 
      setTimeout(() => {
        // this.getNota();
        this.getData();
        this.getSatuan();
    }, 1000);
    this.getTanggal();
      document.addEventListener('keydown',function(e){
        if(e.key=='+' || e.key=='='){
          that.tambah()
        }else if(e.key=='-'){
          that.kurang()
        }
        if(e.key=='F1'){
          e.preventDefault()
          that.$el.querySelector('.vs__search').focus();
        }else if(e.key=='F2'){
          e.preventDefault();
          that.kurang()
        }else if(e.key=='F3'){
          e.preventDefault();
          that.tambah()
        }else if(e.key=='F4'){
          e.preventDefault();
          that.$el.querySelector('#selectpelanggan').focus();
        }else if(e.key=='F5'){
          e.preventDefault();
          that.$el.querySelector('#btnsubmit').click();
        }else if(e.key=='F6'){
          e.preventDefault();
          that.$el.querySelector('#btnbayar').click();
          setTimeout(() => {
            that.$el.querySelector('#uang').focus();
          }, 2000);
        }else if(e.key=='F7'){
          e.preventDefault();
          that.$el.querySelector('#keterangan').focus();
        }else if(e.key=='F8'){
          e.preventDefault();
          that.$el.querySelector('#btnbayar2').click();
        }
      })
        const slider = document.querySelector('.items');
        let isDown = false;
        let startX;
        let scrollLeft;
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
  },
};
</script>